import { render, staticRenderFns } from "./notification-card.vue?vue&type=template&id=66bfbc44&scoped=true&"
import script from "./notification-card.vue?vue&type=script&lang=js&"
export * from "./notification-card.vue?vue&type=script&lang=js&"
import style0 from "./notification-card.vue?vue&type=style&index=0&id=66bfbc44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66bfbc44",
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QAvatar,QImg,QCardActions,QBtn,QSpinnerIos,QDialog,QForm,QInput,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QAvatar,QImg,QCardActions,QBtn,QSpinnerIos,QDialog,QForm,QInput})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
