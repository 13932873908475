//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import NotificationListCard from '@/modules/notification/components/notification-list-card.vue';

export default {
  name: 'app-notification-list-page',

  components: {
    [NotificationListCard.name]: NotificationListCard,
    // [NotificationListFilter.name]: NotificationListFilter,
    // [NotificationListToolbar.name]: NotificationListToolbar,
  },

  data() {
    return {
      tab: 'company',
      rowsToBeDisplayed: [],
    }
  }, 
  computed: {
    ...mapGetters({
      firstBatch: 'notification/list/firstBatch',
      secondBatch: 'notification/list/secondBatch',
      newRowsLength: 'notification/list/newRowsLength',

      rows: 'notification/list/rows',
      loading  :'notification/list/loading',
      backgroundLoading:'notification/list/backgroundLoading',

      totalUnreadNotifications: 'notification/list/totalUnreadNotifications',
      unreadCompanyNotifications: 'notification/list/unreadCompanyNotifications', 
      unreadGuideNotifications: 'notification/list/unreadGuideNotifications',
      unreadStoreNotifications: 'notification/list/unreadStoreNotifications',
      unreadOfferNotifications: 'notification/list/unreadOfferNotifications',
      unreadPlaceNotifications: 'notification/list/unreadPlaceNotifications',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return localStorage.getItem('language') == 'ar'
    },
  },
  mounted() {
    const type = this.$route.query.type
    if (type) {
      this.tab = type.replace('Update', '')
    }

    this.doFilter();
    this.doFetchCount();
    // this.doMountTable(this.$refs.table);
  },
  methods: {
    ...mapActions({
      doFetch: 'notification/list/doFetch',
      // setHeaderBg:'layout/setHeaderBg',
      // collapseMenu: 'layout/collapseMenu',
      doFetchNotifications: 'notification/list/doFetchNotifications',
      setLoadMoreLast: 'notification/list/setLoadMoreLast',
      resetCursor: 'notification/list/resetCursor',
      doFetchCount: 'notification/list/doFetchCount',
    }),

    async doFilter() {
      // this.tab
      // const filter = {};
      // return this.doFetch({
      //   filter,
      // });
      await this.resetCursor()
      await this.doFetchNotifications({ type: this.tab, limit: 10 })
      // this.rowsToBeDisplayed = this.firstBatch
      this.rowsToBeDisplayed = this.rows
    },
    // loadMore(){
    //   let tempSize = this.size + 2
    //   if(tempSize <= this.notifications.length){
    //     this.size = tempSize
    //   }
    //   else {
    //     this.size = this.notifications.length
    //   }
    // },
    // doFetchMore(){
    //   this.doFetch({userId: this.currentUser.id, limit: 10})
    //   // this.doFetchNotifications({userId: this.currentUser.id, limit: 2})
    // }
  },

  watch: {
    // firstBatch(newval){
    //   console.log('firstbatch', newval);
    //   if(newval.length > 0){
    //     this.setLoadMoreLast()
    //     newval.forEach(record =>{
    //       var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
    //       if(foundIndex == -1) {
    //         this.rowsToBeDisplayed.push(record)
    //       }
    //     })
    //     this.rowsToBeDisplayed.sort((a, b) =>{
    //         var keyA = new Date(a.createdAt.seconds),
    //             keyB = new Date(b.createdAt.seconds);
    //         if (keyA < keyB) return 1;
    //         if (keyA > keyB) return -1;
    //         return 0;
    //       })
    //   }
    // },
    // secondBatch(oldval, newval){
    //   if(newval.length > 0){
    //     newval.forEach(record =>{
    //      var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
    //      if(foundIndex == -1) {
    //        this.rowsToBeDisplayed.push(record)
    //      }
    //     })
    //     // this.rowsToBeDisplayed.push(...newval)
    //     // console.log('this.rowsToBeDisplayed',this.rowsToBeDisplayed);
    //   }
    // }
  },

};
