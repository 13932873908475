//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import NotificationCard from '@/modules/notification/components/notification-card';
import { NotificationModel } from '@/modules/notification/notification-model';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
// import { i18n } from '@/i18n';

const { fields } = NotificationModel;

export default {
  name: 'app-notification-list-card',
  props: {
    type: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    }, 
  },

  components: {
    [NotificationCard.name]: NotificationCard,
  },

  mounted() {
    console.log('type', this.type);
    console.log('rows', this.rows);
    // debugger
    // this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'notification/list/rows',
      count: 'notification/list/count',
      loading: 'notification/list/loading',
      pagination: 'notification/list/pagination',
      destroyLoading: 'notification/destroy/loading',
      paginationLayout: 'layout/paginationLayout',

      newRowsLength: 'notification/list/newRowsLength',
      moreRows: 'notification/list/moreRows',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),

    hasPermissionToEdit() {
      return new NotificationPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new NotificationPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'notification/list/doChangeSort',
      doChangePaginationCurrentPage: 'notification/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'notification/list/doChangePaginationPageSize',
      doMountTable: 'notification/list/doMountTable',
      doDestroy: 'notification/destroy/doDestroy',
      doFetch: 'notification/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return NotificationModel.presenter(row, fieldName);
    },

    onLoad(index, done) {
      console.log('hena');
      console.log('index = ', index);
      // if (index > 1) {
        setTimeout(() => {
          if (this.moreRows) {
            this.doFetchMore()
            done()
          }
        }, 2000)      
      // } else {
      //   setTimeout(() => {
      //     if (this.moreRows) {
      //       done()
      //     }
      //   }, 200)      
      // }
    },
    doFetchMore(){
      this.doFetch({ type: this.type, limit: 10 })
      // this.doFetchNotifications({userId: this.currentUser.id, limit: 2})
    },
    async doDestroyWithConfirm(id) {
      try {
        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
