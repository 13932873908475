//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
import NotificationViewModal from '@/modules/notification/components/notification-view-modal';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
import moment from 'moment';
import Message from '@/shared/message/message';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
const db = firebase.firestore();

import { NotificationModel } from '@/modules/notification/notification-model';
const { fields } = NotificationModel;

export default {
  name: 'app-notification-card',
  props: {
    type: {
      type: String,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    }, 
  },
  components: {
    [NotificationViewModal.name]: NotificationViewModal,
  },

  // async created() {
  //   this.model = formSchema.initialValues(this.record || {}); 
  // },
  mounted() {
    const modalType = this.$route.query.type
    const id = this.$route.query.id

    if (modalType && id) {
      if (id == this.record.id) {
        this.doMarkNotificationAsRead()
      }
    }
  },  
  firestore() {
    console.log(this.itemId);
    console.log(this.collectionName);
    return {
      itemDetails: db.collection(this.collectionName).doc(this.itemId),
    }
  },
  data() {
    return {
      acceptLoading: false,
      ignoreLoading: false,
      dialogVisible: false,
      modalVisible: false,
      confirmRejection: false,
      dialog: false,

      itemDetails: {},
      itemId: this.record.itemId,
    };
  },
  computed: {
    ...mapGetters({
      // record: 'notification/form/record',
      findLoading:'notification/form/findLoading',
      saveLoading: 'notification/form/saveLoading',
      
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    },
    hasPermissionToEdit() {
      return new NotificationPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new NotificationPermissions(this.currentUser).destroy;
    },
    collectionName() {
      // return this.type == 'offer' ? 'offer' : 'user'
      let collectionName = ''
      switch (this.type) {
        case 'company':
          collectionName = 'company'
          break;
        case 'offer':
          collectionName = 'offer'
          break;
        case 'place':
          collectionName = 'place'
          break;  
        default:
          collectionName = 'user'
          break;
      }
      return collectionName
    },
  },
  methods: {
    ...mapActions({
      // doFetch: 'notification/list/doFetch',
      doSetAction: 'notification/form/doSetAction',
      doMarkAsRead: 'notification/form/doMarkAsRead',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = NotificationModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      console.log('language in presenterMap',this.language)
      const val = NotificationModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = NotificationModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(NotificationModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = NotificationModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },


    async doAccept() {
      this.acceptLoading = true
      this.record.read = true
      const { id, ...values } = this.record;
      values.action = 'accepted'
      await this.doSetAction({
        id,
        values,
      })
      this.acceptLoading = false
    },
    async doIgnore() {
      this.confirmRejection = false
      this.ignoreLoading = true
      this.record.read = true
      const { id, ...values } = this.record;
      values.action = 'rejected'
      await this.doSetAction({
        id,
        values,
      })
      this.ignoreLoading = false
    },
    async doMarkNotificationAsRead() {
      console.log(this.itemDetails);
      console.log(this.record);
      if (!this.record.read) {
        this.record.read = true
        this.doMarkAsRead(this.record.id)
      }
      if (this.itemDetails) {
        this.doOpenModal();
      } else {
        Message.warning(this.i18n(`errors.${this.type}NotAvailable`));
      }
    },
    doOpenModal() {
      this.modalVisible = true
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false
      }, 200);
    },
  },

};
