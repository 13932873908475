//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';
import ViewCompany from '@/modules/notification/components/view-company';
import ViewGuide from '@/modules/notification/components/view-guide';
import ViewStore from '@/modules/notification/components/view-store';
import ViewOffer from '@/modules/notification/components/view-offer';
import ViewPlace from '@/modules/notification/components/view-place';

import FirebaseRepository from '@/shared/firebase/firebase-repository';

export default {
  name: 'app-notification-view-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    }, 
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    [ViewCompany.name]: ViewCompany,
    [ViewGuide.name]: ViewGuide,
    [ViewStore.name]: ViewStore,
    [ViewOffer.name]: ViewOffer,
    [ViewPlace.name]: ViewPlace,
  },

  data() {
    return {
      language: getLanguageCode(),
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    recordDetails() {
      const record = this.details ? { ...this.details, exists: true } : null
      FirebaseRepository.replaceAllTimestampToDate(record)
      return record
    },
  },
  methods: {
    i18n(code, args) {
      return i18n(code, args);
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
  },
  mounted(){
    console.log('notification view modal', this.value);
  }
};
