//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

import { StoresModel } from '@/modules/stores/stores-model';
const { fields } = StoresModel;

export default {
  name: 'app-view-place',
  props: {
    value: {
      type: Object,
      required: true,
    }, 
    details: {
      type: Object,
      required: false,
    },
  },
  

  mounted() {
    this.tab = this.isUpdated ? 'updated-version' : undefined  
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   map.panTo({lat: 1.38, lng: 103.80})
    //   console.log(map);
    //   debugger
    // })
    // debugger

    //  axios
    //     .get(`https://maps.google.com/maps/api/geocode/json?latlng=${this.details.location.latitude},${this.details.location.longitude}&key=${GOOGLE_API_KEY}`)
    //       .then(response => {
    //         console.log(response.data)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    //     .finally(() => this.loading = false)
  },

  data() {
    return {
      dialog: false,
      tab: undefined,
      acceptLoading: false, 
      ignoreLoading: false,

      // center: { lat: 31.2229972, lng: 29.9450273 },
      // markers: [
      //     {
      //     position: { lat: 31.2229972, lng: 29.9450273 },
      //     },
      // ],
      center: { lat: this.details.location.latitude, lng: this.details.location.longitude },
      markers: [
          {
          position: { lat: this.details.location.latitude, lng: this.details.location.longitude },
          },
      ],
      locationName: '',



      colorValue: '000000',
      defaultColor: '#FF0000',
      radiusValue: 600,

      address: {},
      citiesOptions: [],
      displayPicker: false,
      displaySPicker: false,

      currentPlace: null,
      places: [],
      fillColor: '#AFE3E6',
      strokeColor: '#CEEAEB',
      strokeWeight: 100,
    };
  },
  computed: {
    ...mapGetters({
      findLoading:'notification/form/findLoading',
      saveLoading: 'notification/form/saveLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
    avatar() {
      if (this.isThisFieldUpdated('avatar')) {
        return this.value.updates && this.value.updates.avatar ? this.value.updates.avatar.publicUrl : 'img:/images/icons/ic-notification-read.svg'
      } else {
        return this.value.image ? this.value.image : 'img:/images/icons/ic-notification-read.svg'
      }
    },
    taxCard() {
      if (this.isThisFieldUpdated('taxCard')) {
        return this.value.updates && this.value.updates.taxCard ? this.value.updates.taxCard.publicUrl : null
      } else {
        return this.details && this.details.taxCard ? this.details.taxCard.publicUrl : null
      }
    },
    isUpdated() {
      return this.value.type.includes('Update')
    },
    isThisFieldUpdated() {
      return (fieldName) => { 
        if (fieldName == 'storeLinks') {
          return this.tab == 'updated-version' && ('webLink' in this.value.updates || 'twitterLink' in this.value.updates || 'facebookLink' in this.value.updates || 'instagramLink' in this.value.updates) 
        } else {
          return this.tab == 'updated-version' && (fieldName in this.value.updates) 
        }
      }
    },

  },

  methods: {
    i18n(code, args) {
      return i18n(code, args);
    },
    doClose() {
      this.$emit('close')
    },
    doAccept() { 
      this.acceptLoading = true
      this.$emit('accept'); 
      setTimeout(() => {
        this.acceptLoading = false
        this.$emit('close')
      }, 1000);
    },
    doReject() { 
      // this.rejectLoading = true
      this.$emit('reject'); 
        this.$emit('close')
      // setTimeout(() => {
      //   this.rejectLoading = false
      // }, 1000);
    },
    presenter(row, fieldName) {
      const val = StoresModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterDetailsLocalization(details, fieldName){
       const val = details && details[fieldName] ? details[fieldName] : null;      
      return val ?  this.isRTL ? val['ar'] : val['en'] : '___';   
    },
    presenterDetails(details, fieldName) {
      const val = details && details[fieldName] ? StoresModel.presenter(details, fieldName) : null;
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = StoresModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = StoresModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = StoresModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(StoresModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = StoresModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterStore(row, fieldName, key) {
      const user = StoresModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = StoresModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
    // async doSubmit() {
    //   await this.$emit('submit', this.id)
    // },
  },
 
};
