import { render, staticRenderFns } from "./view-company.vue?vue&type=template&id=5cd49544&scoped=true&"
import script from "./view-company.vue?vue&type=script&lang=js&"
export * from "./view-company.vue?vue&type=script&lang=js&"
import style0 from "./view-company.vue?vue&type=style&index=0&id=5cd49544&lang=scss&scoped=true&"
import style1 from "./view-company.vue?vue&type=style&index=1&id=5cd49544&lang=scss&scoped=true&"
import style2 from "./view-company.vue?vue&type=style&index=2&id=5cd49544&lang=scss&scoped=true&"
import style3 from "./view-company.vue?vue&type=style&index=3&id=5cd49544&lang=scss&scoped=true&"
import style4 from "./view-company.vue?vue&type=style&index=4&lang=scss&"
import style5 from "./view-company.vue?vue&type=style&index=5&id=5cd49544&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd49544",
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QItem,QItemSection,QAvatar,QImg,QItemLabel,QBtn,QSpinnerIos,QTooltip,QSeparator,QTabs,QTab,QTabPanel,QTabPanels,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QItem,QItemSection,QAvatar,QImg,QItemLabel,QBtn,QSpinnerIos,QTooltip,QSeparator,QTabs,QTab,QTabPanel,QTabPanels})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
